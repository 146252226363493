import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
    baseURL: baseURL
}); 

export const getLocation = async () => {
  let result = await api.get(`commission/get_locations/`);
  return result.data;
};
