import React from 'react'
import SignInForm from '../../components/userAuthentication/signInForm'

const SignIn = () => {
  return (
    <SignInForm />
  )
}

export default SignIn
